import {
  Container,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Image from "../ui/Image";

export default function ProfileCardBody() {
  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const imageSize = isSmScreen ? 250 : 200;

  return (
    <Container
      sx={{
        mt: 2,
        p: 2,
        display: "flex",
        flexDirection: isSmScreen ? "column" : "row",
        justifyContent: "start",
        alignItems: "center"
      }}
    >
        <Image source="/assets/profile.jpg" size={imageSize} sxOverrides={{
          p: 0.5,
          bgcolor: "common.black",
          border: "solid",
          borderRadius: "50%",
          borderWidth: "2px",
          borderColor: "common.white",
        }}/>
      <Grid
        sx={{
          mt: isSmScreen ? 3 : 0,
          ml: isSmScreen ? 0 : 5,
          display: "flex",
          flexDirection: "column",
          justifyContent: "right",
          flexWrap: "flex"
        }}
      >
        <Typography textAlign={isSmScreen ? "center" : "left"} variant="h3">Hi 👋, I'm Marco</Typography>
        <Typography textAlign={isSmScreen ? "center" : "left"} variant="h6">
          Fullstack Software Developer
        </Typography>
        <Container
          disableGutters
          sx={{ mt: 2, display: "flex", justifyContent: "left" }}
        >
        </Container>
      </Grid>
    </Container>
  );
}
